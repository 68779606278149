import Mock from "../mock";

const database = {
  information: {
    name: 'Joshua Thomas',
    subHead: "",
    aboutContent: "I'm a hands-on full stack technology leader with top tier skills in cultivating high-velocity engineering squads.",
    phone: '',
    nationality: 'American',
    language: 'English',
    email: '',
    address: 'Austin, TX',
   
    socialLinks: {
      facebook: '',
      twitter: '',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/jarsh',
      dribbble: '',
      github: 'https://github.com/joshuathomasaustintx'
    },
    brandImage: '/images/joshua_thomas.jpg',
    aboutImage: '/images/josh_thomas_img.jpg',
    aboutImageLg: '/images/josh_thomas_img.jpg',
    cvfile: '/files/joshua_thomas_resume.pdf'
  },
  services: [
    {
      title: "Web Design",
      icon: 'brush-alt',
      details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod."
    },
    {
      title: "Web Development",
      icon: 'code',
      details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod."
    },
    {
      title: "Mobile Application",
      icon: 'mobile',
      details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod."
    }
  ],
  reviews: [
    {
      id: 1,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
      author: {
        name: 'Burdette Turner',
        designation: 'Web Developer, Abc Company'
      }
    },
    {
      id: 2,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
      author: {
        name: 'Susan Yost',
        designation: 'Client'
      }
    },
    {
      id: 3,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
      author: {
        name: 'Irving Feeney',
        designation: 'Fiverr Client'
      }
    }
  ],
  skills: [
    {
      title: "JavaScript",
      value: 95
    },
    {
      title: "React",
      value: 90
    },
    {
      title: "PHP",
      value: 70
    },
    {
      title: "Bulma/Flexbox",
      value: 85
    },
    {
      title: "Agile/Scrum",
      value: 85
    },
    {
      title: "SDLC",
      value: 85
    },
    {
      title: "AWS",
      value: 85
    },
    {
      title: "APIs",
      value: 80
    }
  ],

  portfolios: [
    {
      id: 1,
      title: "T-shirt Mockup",
      subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: 'https://dribbble.com'
    },
    {
      id: 2,
      title: "Coffee Mug",
      subtitle: "Awesome coffee mug design.",
      imageUrl: "/images/portfolio-image-4.jpg",
      largeImageUrl: [
        "/images/portfolio-image-4-lg.jpg",
        "/images/portfolio-image-4-lg2.jpg"
      ],
      url: 'https://facebook.com'
    },
    {
      id: 3,
      title: "Tea & Coffee Mug",
      subtitle: "Beautiful mug with logo.",
      imageUrl: "/images/portfolio-image-2.jpg",
      url: 'https://pinterest.com'
    }
  ],

  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2023 - Present",
        position: "Software Engineering Manager, Brand Integrations",
        company: "Cars Commerce",
        details: ""
      },
      {
        id: 2,
        year: "2022 - 2023",
        position: "Senior Software Engineeering Manager",
        company: "Parsable",
        details: ""
      },
      {
        id: 3,
        year: "2020 - 2022",
        position: "Software Engineeering Manager",
        company: "Parsable",
        details: ""
      },
      {
        id: 4,
        year: "2019 - 2020",
        position: "Technical Product Owner, APIs & Integrations",
        company: "HotSchedules",
        details: ""
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2017",
        graduation: "Bachelor's Of Business, Management Information Systems",
        university: "Excelsior University",
        details: ""
      }
    ]
  },
  blogs: [
    {
      id: 1,
      title: 'Markdown & Html supported blog.',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/markdown-html-supported-blog.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 2,
      title: 'Installing NodeJS on your device.',
      featuredImage: '/images/blog-image-2.jpg',
      filesource: '../../blog/installing-nodejs-on-your-device.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    }
  ],
  contactInfo: {
    phoneNumbers: ['', ''],
    emailAddress: ['joshuathomasaustintx@gmail.com'],
    address: "Austin, TX"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});